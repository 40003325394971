import React, {useEffect, useState} from "react";
import {getRefreshToken} from "../http";
import {autoLogout, getToken} from "../utils/utils";

export function useLoadingWithRefresh() {
    const [loading, setLoading] = useState(true);
    const _token = localStorage.getItem('web_access_token');
    const refreshToken = localStorage.getItem('web_refreshToken');

    useEffect(() => {
        // check refresh token in local storage
        if (!refreshToken) {
            autoLogout();
            return;
        } else {
            fetchRefreshToken();
        }

    }, []);

    const fetchRefreshToken = async () => {
        if (_token) {
            const token = getToken(_token);
            if (!token) return;
        }
        if (refreshToken) {
            const {data} = await getRefreshToken({refreshToken});
            localStorage.setItem('web_access_token', data.token);
            setLoading(false);
        } else {
            autoLogout();
            setLoading(false);
        }
    }

    return {loading};
}
