import * as AWS from "aws-sdk";

const S3AWS = {
    S3_ACCESS_KEY: process.env.REACT_APP_ACCESS_KEY,
    S3_SECRET_ACCESS_KEY: process.env.REACT_APP_SECRET_ACCESS_KEY,
    S3_BUCKET: process.env.REACT_APP_S3_BUCKET,
    S3_REGION: process.env.REACT_APP_REGION
}

const INSTANCE_INFO = {
    APP_LOGO: process.env.REACT_APP_LOGO,
    APP_MAIN_LOGO: process.env.REACT_APP_BIG_LOGO,
    APP_CLUB_ID: process.env.REACT_APP_CLUB_ID,
    APP_TITLE: process.env.REACT_APP_TITLE,
    APP_FAVICON: process.env.REACT_APP_FAVICON,
    APP_PLACEHOLDER: process.env.REACT_APP_PLACEHOLDER
}

export const constants = {
    BASE_URL: process.env.REACT_APP_API_URL,
    EVENT_URL: process.env.REACT_APP_EVENT_URL,
    PASSWORD_RESET_ONLY: process.env.REACT_APP_PASSWORD_RESET_ONLY,
    PASSWORD_RESET_LINK: process.env.REACT_APP_PASSWORD_RESET_LINK,
    RANDOM_STRING: process.env.REACT_APP_RAND_STRING,
    FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
    S3AWS,
    INSTANCE_INFO,
};

export const FIREBASE = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_APP_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID,
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
};

export const AWSBucket = new AWS.S3({
    accessKeyId: S3AWS.S3_ACCESS_KEY,
    secretAccessKey: S3AWS.S3_SECRET_ACCESS_KEY,
    params: {Bucket: S3AWS.S3_BUCKET},
    region: S3AWS.S3_REGION,
});
