import React, {useEffect, useState} from 'react';
import Offcanvas from "react-bootstrap/Offcanvas";
import {IoCloseOutline} from "react-icons/io5";
import './Notification.css'
import {getNotifications} from "../../http";
import Load from "react-js-loader";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import Loader from "../Loader/Loader";

const Notification = (props) => {
    const [notificationList, setNotificationList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(async () => {
        if (count !== notificationList.length || notificationList.length === 0) {
            await fetchNotificationList(page);
        }
    }, [page, props.show]);

    const fetchNotificationList = async () => {
        if (props.show) {
            if(page === 1) setLoading(true);
            const {data} = await getNotifications(page);
            if (data) {
                setNotificationList([...notificationList, ...data.data]);
                setCount(data.count);
            }
            setLoading(false);
        }
    };

    const onCloseBtn = () => {
        props.closeExpand(false);
        setPage(1)
    }

    const notificationTitle = (e) => {
        let strTitle;
        let strDescription;
        const displayName = e?.sender?.displayName
        if (e.event === 'friendsRequestReceived') {
            strTitle = "New Friend Request"
            strDescription = `${displayName} has sent you a friend request`
        } else if (e.event === 'friendsRequestAccepted') {
            strTitle = "New Friend Accepted"
            strDescription = `${displayName} has accepted your friend request`
        } else if (e.event === 'friendsRequestDecline') {
            strTitle = "Friend Request Decline"
            strDescription = `${displayName} has decline your friend request`
        } else if (e.event === 'friendRemove') {
            strTitle = "Friend Remove"
            strDescription = `${displayName} has removed from friend`
        } else if (e.event === 'imsSendMessage') {
            strTitle = "Message Received"
            strDescription = `${displayName} sent a message`
        } else if (e.event === 'imsMessageUpdate') {
            strTitle = "Message Updated"
            strDescription = `${displayName} has update a message`
        } else if (e.event === 'deleteMessage') {
            strTitle = "Message Deleted"
            strDescription = `${displayName} has deleted a message`
        } else if (e.event === 'imsLeaveChatGroup') {
            strTitle = "Leave to Chat"
            strDescription = `${displayName} has leave a chat`
        } else if (e.event === 'imsJoinChatGroup') {
            strTitle = "Added to Chat"
            strDescription = `${displayName} has added you to chat`
        } else if (e.event === 'imsDeleteChatGroup') {
            strTitle = "Deleted to Chat"
            strDescription = `${e.sender.displayName} has deleted chat group`
        } else if (e.event === 'newWallPost') {
            strTitle = "Friend Posted"
            strDescription = `${displayName} has created a new post`
        } else if (e.event === 'updatedWallPost') {
            strTitle = "Friend edited Post"
            strDescription = `${displayName} has edited a post`
        } else if (e.event === 'deletedWallPost') {
            strTitle = "Friend Post Deleted"
            strDescription = `${displayName} has deleted a post`
        } else if (e.event === 'updatedWallPostLike') {
            strTitle = "Like Received"
            strDescription = `${displayName} has Liked your post`
        } else if (e.event === 'newWallPostComment') {
            strTitle = "Comment Received"
            strDescription = `${displayName} has commented on your post`
        } else if (e.event === 'updatedWallPostComment') {
            strTitle = "Comment Edited"
            strDescription = `${displayName} has edited a comment on your post`
        } else if (e.event === 'deletedWallPostComment') {
            strTitle = "Comment Deleted"
            strDescription = `${displayName} has deleted comment`
        } else if (e.event === 'newNews') {
            strTitle = "News Post"
            strDescription = `${displayName} has created a new post`
        } else if (e.event === 'updatedNewsPost') {
            strTitle = "News Post"
            strDescription = `${displayName} has edited a post`
        } else if (e.event === 'deletedNewsPost') {
            strTitle = "News Post"
            strDescription = `${displayName} has deleted a post`
        } else if (e.event === 'newNewsComment') {
            strTitle = "News Post"
            strDescription = `${displayName} has commented on post`
        } else if (e.event === 'updatedNewsLike') {
            strTitle = "News Post"
            strDescription = `${displayName} has Liked post`
        } else if (e.event === 'updatedNewsPostComment') {
            strTitle = "News Post"
            strDescription = `${displayName} has edited a comment on post`
        } else if (e.event === 'deletedNewsPostComment') {
            strTitle = "News Post"
            strDescription = `${displayName} has deleted comment`
        } else if (e.event === 'newSocial') {
            strTitle = "Social Post"
            strDescription = `${displayName} has created a new post`
        } else if (e.event === 'updatedSocialPost') {
            strTitle = "Social Post"
            strDescription = `${displayName} has edited a post`
        } else if (e.event === 'deletedSocialPost') {
            strTitle = "Social Post"
            strDescription = `${displayName} has deleted a post`
        } else if (e.event === 'newSocialComment') {
            strTitle = "Social Post"
            strDescription = `${displayName} has commented on post`
        } else if (e.event === 'updatedSocialLike') {
            strTitle = "Social Post"
            strDescription = `${displayName} has Liked post`
        } else if (e.event === 'updatedSocialPostComment') {
            strTitle = "Social Post"
            strDescription = `${displayName} has edited a comment on post`
        } else if (e.event === 'deletedSocialPostComment') {
            strTitle = "Social Post"
            strDescription = `${displayName} has deleted comment`
        } else if (e.event === 'newRumor') {
            strTitle = "Rumors Post"
            strDescription = `${displayName} has created a new post`
        } else if (e.event === 'updatedRumorPost') {
            strTitle = "Rumors Post"
            strDescription = `${displayName} has edited a post`
        } else if (e.event === 'deletedRumorPost') {
            strTitle = "Rumors Post"
            strDescription = `${displayName} has deleted a post`
        } else if (e.event === 'newRumorComment') {
            strTitle = "Rumors Post"
            strDescription = `${displayName} has commented on post`
        } else if (e.event === 'updatedRumorLike') {
            strTitle = "Rumors Post"
            strDescription = `${displayName} has Liked post`
        } else if (e.event === 'updatedRumorPostComment') {
            strTitle = "Rumors Post"
            strDescription = `${displayName} has edited a comment on post`
        } else if (e.event === 'deletedRumorPostComment') {
            strTitle = "Rumors Post"
            strDescription = `${displayName} has deleted comment`
        } else if (e.event === 'newVideo') {
            strTitle = "Club TV Post"
            strDescription = `${displayName} has created a new post`
        } else if (e.event === 'updatedVideoPost') {
            strTitle = "Club TV Post"
            strDescription = `${displayName} has edited a post`
        } else if (e.event === 'deletedVideoPost') {
            strTitle = "Club TV Post"
            strDescription = `${displayName} has deleted a post`
        } else if (e.event === 'newVideoComment') {
            strTitle = "Club TV Post"
            strDescription = `${displayName} has commented on post`
        } else if (e.event === 'updatedVideoLike') {
            strTitle = "Club TV Post"
            strDescription = `${displayName} has Liked post`
        } else if (e.event === 'updatedVideoPostComment') {
            strTitle = "Club TV Post"
            strDescription = `${displayName} has edited a comment on post`
        } else if (e.event === 'deletedVideoComment') {
            strTitle = "Club TV Post"
            strDescription = `${displayName} has deleted comment`
        } else {
            strTitle = "New"
            strDescription = e.title
        }
        return {strTitle, strDescription};
    }

    const redirectToPage = (e) => {
        if (e.event === 'friendsRequestReceived') {
            navigate(`/user-profile/${e.sender._id}`)
        } else if (e.event === 'friendsRequestAccepted') {
            navigate(`/user-profile/${e.sender._id}`)
        } else if (e.event === 'friendsRequestDecline') {
            navigate(`/user-profile/${e.sender._id}`)
        } else if (e.event === 'friendRemove') {
            navigate(`/user-profile/${e.sender._id}`)
        } else if (e.event === 'imsSendMessage') {
            const state = e.groupChatId;
            navigate(`/ims-chats`, {state})
        } else if (e.event === 'imsMessageUpdate') {
            const state = e.groupChatId;
            navigate(`/ims-chats`, {state})
        } else if (e.event === 'deleteMessage') {
            const state = e.groupChatId;
            navigate(`/ims-chats`, {state})
        } else if (e.event === 'imsLeaveChatGroup') {
            const state = e.groupChatId;
            navigate(`/ims-chats`, {state})
        } else if (e.event === 'imsJoinChatGroup') {
            const state = e.groupChatId;
            navigate(`/ims-chats`, {state})
        } else if (e.event === 'imsDeleteChatGroup') {
            navigate(`/ims-chats`)
        } else if (e.event === 'newWallPost') {
            navigate(`/posts/${e.postId}`)
        } else if (e.event === 'updatedWallPost') {
            navigate(`/posts/${e.postId}`)
        } else if (e.event === 'deletedWallPost') {
            navigate(`/posts/${e.postId}`)
        } else if (e.event === 'updatedWallPostLike') {
            navigate(`/posts/${e.postId}`)
        } else if (e.event === 'newWallPostComment') {
            navigate(`/posts/${e.postId}`)
        } else if (e.event === 'updatedWallPostComment') {
            navigate(`/posts/${e.postId}`)
        } else if (e.event === 'deletedWallPostComment') {
            navigate(`/posts/${e.postId}`)
        } else if (e.event === 'newNews') {
            navigate(`/news/${e.postId}`)
        } else if (e.event === 'updatedNewsPost') {
            navigate(`/news/${e.postId}`)
        } else if (e.event === 'deletedNewsPost') {
            navigate(`/news/${e.postId}`)
        } else if (e.event === 'newNewsComment') {
            navigate(`/news/${e.postId}`)
        } else if (e.event === 'updatedNewsLike') {
            navigate(`/news/${e.postId}`)
        } else if (e.event === 'updatedNewsPostComment') {
            navigate(`/news/${e.postId}`)
        } else if (e.event === 'deletedNewsPostComment') {
            navigate(`/news/${e.postId}`)
        } else if (e.event === 'newSocial') {
            navigate(`/social/${e.postId}`)
        } else if (e.event === 'updatedSocialPost') {
            navigate(`/social/${e.postId}`)
        } else if (e.event === 'deletedSocialPost') {
            navigate(`/social/${e.postId}`)
        } else if (e.event === 'newSocialComment') {
            navigate(`/social/${e.postId}`)
        } else if (e.event === 'updatedSocialLike') {
            navigate(`/social/${e.postId}`)
        } else if (e.event === 'updatedSocialPostComment') {
            navigate(`/social/${e.postId}`)
        } else if (e.event === 'deletedSocialPostComment') {
            navigate(`/social/${e.postId}`)
        } else if (e.event === 'newRumor') {
            navigate(`/rumors/${e.postId}`)
        } else if (e.event === 'updatedRumorPost') {
            navigate(`/rumors/${e.postId}`)
        } else if (e.event === 'deletedRumorPost') {
            navigate(`/rumors/${e.postId}`)
        } else if (e.event === 'newRumorComment') {
            navigate(`/rumors/${e.postId}`)
        } else if (e.event === 'updatedRumorLike') {
            navigate(`/rumors/${e.postId}`)
        } else if (e.event === 'updatedRumorPostComment') {
            navigate(`/rumors/${e.postId}`)
        } else if (e.event === 'deletedRumorPostComment') {
            navigate(`/rumors/${e.postId}`)
        } else if (e.event === 'newVideo') {
            navigate(`/rumors/${e.postId}`)
        } else if (e.event === 'updatedVideoPost') {
            navigate(`/rumors/${e.postId}`)
        } else if (e.event === 'deletedVideoPost') {
            navigate(`/rumors/${e.postId}`)
        } else if (e.event === 'newVideoComment') {
            navigate(`/rumors/${e.postId}`)
        } else if (e.event === 'updatedVideoLike') {
            navigate(`/rumors/${e.postId}`)
        } else if (e.event === 'updatedVideoPostComment') {
            navigate(`/rumors/${e.postId}`)
        } else if (e.event === 'deletedVideoComment') {
            navigate(`/rumors/${e.postId}`)
        } else {
            navigate(`/`)
        }
        props.closeExpand(false);
    }

    const redirectToProfile = (link) => {
        // navigate('/profile');
        props.closeExpand(false);
    }

    return (
        <>
            <Offcanvas
                className="notification-drawer drawer-menu bg-light"
                placement="end"
                show={props.show} onHide={props.closeExpand}>
                <div className="drawer-header bg-green-primary d-flex justify-content-between align-items-center">
                    <div/>
                    <div className="drawer-header-main" onClick={redirectToProfile}>
                        <div className="drawer-header-image flexing position-relative">
                            <h1 className="set-notify-title fw-bold">Notifications</h1>
                        </div>
                    </div>
                    <div className="drawer-cancel-icon pt-0 p-2 fs-3 text-end pointer">
                        <IoCloseOutline onClick={onCloseBtn}/>
                    </div>
                </div>
                {loading ? <Loader/> :
                    <div id="notificationScrollDiv" className="set-notify-content">
                        {notificationList.length !== 0 ?
                            <InfiniteScroll
                                scrollableTarget="notificationScrollDiv"
                                dataLength={notificationList.length}
                                    next={() => setPage(page => page + 1)}
                                hasMore={true}
                                loader={count === notificationList.length ? '' :
                                    <Load
                                        type="spinner-club"
                                        bgColor={"#FFFF00"}
                                        size={40}
                                    />}>
                                <div className="w-100">
                                    {notificationList && notificationList.map((notification) => {
                                            const {strTitle, strDescription} = notificationTitle(notification);
                                            return (
                                                <div className="set-notification-box d-flex rounded-1 gap-2 pointer"
                                                     key={notification._id}
                                                     onClick={() => redirectToPage(notification)}>
                                                    <div className="set-circle-notify flexing">
                                                        <i className="fa fa-solid fa-bell fs-4"
                                                           aria-hidden="true"
                                                           style={{color: "#356A7E"}}/>
                                                    </div>
                                                    <div className="w-100">
                                                        <h5 className="text-green-primary fw-bold notification-title">{strTitle}</h5>
                                                        <p className="text-black mb-1 notification-desc fs-6">{strDescription}</p>
                                                        <p className="text-secondary text-end mb-0 fs-8">{moment(notification.created).fromNow()}</p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                            </InfiniteScroll> :
                            <div className="text-dark m-auto d-flex justify-content-center align-items-center h-100">
                                <p>No notifications</p>
                            </div>
                        }
                    </div>
                }
            </Offcanvas>
        </>
    )
}

export default Notification
