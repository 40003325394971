import React from 'react';
import {constants} from "../../config/constants";

function ImageTag({_image, alt, isProfile, _class, onClick}) {

    const defaultImage = isProfile ? '/assets/images/blank_profile.png' : `/assets/images/placeholder/${constants.INSTANCE_INFO.APP_PLACEHOLDER}`;

    return (
        <img className={_class}
             src={_image || defaultImage}
             onError={(e) => {
                 e.target.onerror = null;
                 e.target.src = defaultImage
             }}
             onClick={onClick}
             alt={alt}/>
    );
}

export default ImageTag;
