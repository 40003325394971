import React, {useEffect, useState} from 'react';
import {constants} from "../../config/constants";
import './Header.css';
import {Container, Navbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import {Link} from "react-router-dom";
import Notification from "../../components/Notification/Notification";

const notify = localStorage.getItem('notify');
const Header = (props) => {
    const token = localStorage.getItem('web_access_token');
    const [expend, setExpend] = useState(false);
    const [noty, setNoty] = useState(+notify);

    useEffect(() => {
        setNoty(props.notifyCount)
    },)

    const openExpand = () => {
        setExpend(true);
    }

    const closeExpand = () => {
        setExpend(false);
    }

    return (
        <>
            <Navbar className="d-flex justify-content-between align-items-center">
                <Container>
                    <Link to="/">
                        <Navbar.Brand>
                            <img
                                src={`/assets/images/logos/${constants.INSTANCE_INFO.APP_MAIN_LOGO}`}
                                alt="logo"
                                width="auto"
                                height="40"
                                className="d-inline-block align-top"
                            />
                        </Navbar.Brand>
                    </Link>
                    <div className="d-flex align-items-center justify-content-center gap-3">
                        <div id="google_translate_element"/>
                        {token && (<>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip>Notifications</Tooltip>}>
                                <div onClick={openExpand}>
                                    <img src="/assets/images/icons/notif.png" alt="notifications" width="30" height="30"
                                         className="pointer"/>
                                </div>
                            </OverlayTrigger>

                            <Notification
                                show={expend}
                                closeExpand={closeExpand}
                            />
                        </>)
                        }
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>Friends</Tooltip>}>
                            <Link to="/friends">
                                <div className="position-relative">
                                    <img src="/assets/images/icons/ppl_icon.png" alt="notifications" width="30"
                                         height="30"/>
                                    {noty === 1 && <div className="position-absolute eclipse"/>}
                                </div>
                            </Link>
                        </OverlayTrigger>
                    </div>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;
