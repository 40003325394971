import React, {useEffect, useState} from 'react';
import {Carousel} from "react-bootstrap";
import '../Ticker/ticker.css';
import {getTicker} from "../../http";
import {isEmpty} from "lodash";

const Ticker = () => {
    const [ticker, setTicker] = useState({});
    useEffect(() => {
        getTicker().then((res) => {
            setTicker(res.data)
        })
    }, [])


    return (
        <div>
            {!isEmpty(ticker) &&
            <Carousel
                controls={false}
                indicators={false}
                interval={5000}
                className="flexing ticker-bg">

                {ticker && ticker?.messages?.map((item, index) => {
                    console.log("ticker", ticker, item);
                    return (
                        <Carousel.Item className="text-center p-1 text-white" key={index}>
                            {item}
                        </Carousel.Item>
                    )
                })}
            </Carousel>
            }
        </div>

    );
}

export default Ticker;
