import {constants} from "../config/constants";
import MicRecorder from "mic-recorder-to-mp3";
import {toast} from "react-hot-toast";
import jwt_decode from "jwt-decode";

export function generateString(length) {
    const characters = constants.RANDOM_STRING;
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export const autoLogout = () => {
    localStorage.removeItem('web_user');
    localStorage.removeItem('web_access_token');
    localStorage.removeItem('web_refreshToken');
    localStorage.removeItem('web_fireBase_token');
    // return useHistory().push('/');
}

export const getToken = (token) => {
    const decodedToken = jwt_decode(token);
    const currentDate = new Date();
    const isCheckTokenExpTime = decodedToken.exp * 1000 < currentDate.getTime();
    if(isCheckTokenExpTime) {
        autoLogout();
        return;
    }
    return token;
}


// export function generatePassword(length) {
//     const characters = constants.RANDOM_NUMBER;
//     let result = '';
//     const charactersLength = characters.length;
//     for (let i = 0; i < length; i++) {
//         result += characters.charAt(Math.floor(Math.random() * charactersLength));
//     }
//
//     return result;
// }
//
// export function createRandomEmail(){
//     const epoch = new Date().valueOf();
//     const randString = generateString(5);
//     return epoch+randString+constants.UNIQUE_STRING;
// }

export const numFormatter = (num) => {
    if(num > 999 && num < 1000000){
        return (num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million
    }else if(num > 1000000){
        return (num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million
    }else if(num < 900){
        return num; // if value < 1000, nothing to do
    }
}

//setRecorder gives a MicRecorder object, by default bitRate=128

export const setRecorder = (bitRate = 128) => {
    return new MicRecorder({
        bitRate: bitRate
    });
};

//To start recording

export const startRecord = (recorder, setData, setRecord) => {
    recorder
        .start()
        .then(() => {
            setRecord(true);
            toast.success("Recording started", {duration: 5000});
            setData(null);
            //
        })
        .catch((e) => {
            setRecord(false);
            toast.error("Please attached your mic", {duration: 5000});
            console.error("Error:", e);
        });
};
//To stop recording and it also gives us blob and buffer
//setBlob is the function that takes the blob into the component state
export const stopRecord = (recorder, setBlob, setAudioUrl,  setRecord, setBuffer = null) => {
    recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
            setRecord(false);
            setBlob(blob);
            setAudioUrl(window.URL.createObjectURL(blob));
            if (setBuffer != null) {
                setBuffer(buffer);
            }
        })
        .catch((e) => {
            console.log("Error:", e);
            setRecord(false);
        });
};

